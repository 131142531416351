<template>
	<div class="container">
		<web-header />
		<router-title class="bread_crumbs" :menuList="menuList[0]" :list="menuList[1]" />
		<div class="content">
			<!-- 播放区域 -->
			<div class="play_area">
				<div class="title">{{currentPlayItem.videoName}}</div>
				<video-player class="video-player vjs-custom-skin" ref="videoPlayer" :playsinline="true"
					@play="onPlayerPlay($event)" @pause="onPlayerPause($event)" :options="playerOptions" />
			</div>
			<!-- 列表区域 -->
			<div class="list_area">
				<div v-for="(item,index) in playList" :key="index" class="item"
					:class="{'active_item':currentPlayIndex === index}" @click="handleChangeVideo(item,index)">
					<div class="item_l">
						<span class="cicle"></span>
						<div class="name">{{index+1}}.{{item.videoName}}</div>
					</div>
					<div class="item_r">
						<img class="icon" src="../../assets/live/stop_icon.png" alt=""
							v-if="currentPlayIndex === index && isPlay">
						<img class="icon" src="../../assets/live/play_red_icon.png" alt=""
							v-else-if="currentPlayIndex === index && !isPlay">
						<img class="icon" src="../../assets/live/play_icon.png" alt="" v-else>
						<span class="time">{{timeData(item.videoTime)}}</span>
					</div>
				</div>
			</div>
		</div>
		<web-footer />
	</div>
</template>
<script>
	import WebFooter from '../../components/webFooter.vue'
	import webHeader from '../../components/webHeader.vue'
	import RouterTitle from "../../components/routerTitle.vue";
	export default {
		components: {
			webHeader,
			WebFooter,
			RouterTitle
		},
		data() {
			return {
				menuList: ["首页", "直播回放"],
				playList: [], //播放列表
				currentPlayIndex: 0,
				currentPlayItem: {}, //当前播放项
				playerOptions: {}, //播放器配置
				isPlay: false, //是否正在播放
			}
		},
		created() {
			//禁止鼠标右键菜单
			document.oncontextmenu = function(e) {
				return false;
			};
			if (localStorage.getItem('liveReplay')) {
				this.playList = JSON.parse(localStorage.getItem('liveReplay'))
				this.currentPlayIndex = Number(this.$route.query.index) || 0
				this.currentPlayItem = this.playList[this.currentPlayIndex]
				setTimeout(() => {
					this.videoOptions()
				}, 300);
			}
		},
		methods: {
			// 时间转换
			timeData(time) {
				var hh;
				var mm;
				var ss;
				//传入的时间为空或小于0
				if (time == null || time < 0) {
					return;
				}
				//得到小时
				hh = time / 3600 | 0;
				time = parseInt(time) - hh * 3600;
				if (parseInt(hh) < 10) {
					hh = "0" + hh;
				}
				//得到分
				mm = time / 60 | 0;
				//得到秒
				ss = parseInt(time) - mm * 60;
				if (parseInt(mm) < 10) {
					mm = "0" + mm;
				}
				if (ss < 10) {
					ss = "0" + ss;
				}
				// if (hh == '00') return mm + ":" + ss
				// if (hh == '00' && mm == '00') return ss
				return hh + ":" + mm + ":" + ss;
			},
			//视频配置
			videoOptions() {
				this.playerOptions = {
					autoplay: false, //如果true,浏览器准备好时开始回放。
					muted: false, // 默认情况下将会消除任何音频。
					loop: false, // 导致视频一结束就重新开始。
					preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
					language: 'zh-CN',
					aspectRatio: '16:9', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
					fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
					sources: [{
						type: "", //这里的种类支持很多种：基本视频格式、直播、流媒体等，具体可以参看git网址项目
						src: this.currentPlayItem.recordUrl //url地址
					}],
					poster: this.currentPlayItem.recordSurfacePlot, //你的封面地址
					// width: document.documentElement.clientWidth, //播放器宽度
					notSupportedMessage: '此视频暂无法播放，请稍后再试', //允许覆盖Video.js无法播放媒体源时显示的默认信息。
					controlBar: {
						timeDivider: true,
						durationDisplay: true,
						remainingTimeDisplay: false,
						fullscreenToggle: true //全屏按钮
					}
				}
			},
			// 视频开始播放
			onPlayerPlay(e) {
				this.isPlay = true
			},
			// 视频暂停
			onPlayerPause(e) {
				this.isPlay = false
			},
			// 切换视频
			handleChangeVideo(item, i) {
				this.currentPlayIndex = i;
				this.currentPlayItem = item;
				this.isPlay = false;
				this.videoOptions()
			}
		}
	}
</script>
<style lang="less" scoped>
	.container {
		background: #EEEEEE;
	}

	.bread_crumbs {
		padding-left: 112px;
	}

	.content {
		display: flex;
		padding-bottom: 30px;
	}

	.play_area {
		flex: 10;

		.title {
			height: 90px;
			line-height: 90px;
			padding-left: 113px;
			background: #FFFFFF;
			font-size: 24px;
			font-weight: bold;
			color: #333333;
		}
	}

	.list_area {
		margin-left: 25px;
		flex: 4;
		background: #FFFFFF;
		padding-top: 15px;

		.item {
			cursor: pointer;
			display: flex;
			justify-content: space-between;
			align-items: center;
			// margin-bottom: 24px;
			padding: 0 29px 0 31px;

			&:nth-last-child(1) .item_l,
			&:nth-last-child(1) .item_r {
				padding-bottom: 0px;
			}

			.item_l {
				position: relative;
				padding-left: 16px;
				border-left: 1px solid#39AEFF;
				padding-bottom: 24px;
				flex: 2;

				.cicle {
					display: block;
					width: 5px;
					height: 5px;
					background: #39AEFF;
					border-radius: 50%;
					position: absolute;
					left: -3px;
				}

				.name {
					font-size: 16px;
					color: #333333;
					text-overflow: ellipsis;
					overflow: hidden;
					white-space: nowrap;
					width: 200px;
				}
			}

			.item_r {
				display: flex;
				align-items: center;
				padding-bottom: 24px;

				.time {
					font-size: 16px;
					color: #999999;
					margin-left: 10px;
				}
			}
		}

		.active_item {
			.item_l {
				.name {
					color: #39AEFF;
				}
			}

			.item_r {
				.time {
					color: #39AEFF;
				}
			}
		}
	}
</style>
